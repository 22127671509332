
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10%; 
    background-color: rgba(51,51,51,0.5);
    opacity: 0.5;
    color: white;
  }
  
  .navbar-logo img {
    height: 50px;
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin-bottom: 0;
  }
  
  .navbar-links li {
    display: inline;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  .navbar-links .download-link {
    padding: 5px 10px;
    border-radius: 25px;
    background-image: linear-gradient(rgba(57, 146, 180, 1), rgba(131, 43, 210, 1));
  }
  
  .navbar-links a:hover {
    color: #ff5733;
  }

  .activeShow{
    border: 1px solid white;
    padding: 7px;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 840px) {
    .navbar {
      flex-wrap: wrap;
      margin-inline: 0;
      max-width: 100%;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #333;
    }
  
    .navbar-links.open {
      display: flex;
      min-height: 50%;
    }
  
    .navbar-links li {
      display: block;
      padding: 10px 0;
      text-align: center;
    }
  }
  
  