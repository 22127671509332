.headBody{
    margin: 12% 10% 0 10%;
    display: inline-flex;
    width: 80%;
    flex-wrap: wrap;
}

#logo{
    width: 50%;
    background-color: black;
    margin-bottom: 15px;
}

.intro{
    width: 50%;
    padding-bottom: 10% ;
    padding-right: 5%;
}

.intro button{
    margin: 10px;
    border-radius: 16px;
}


.intro.introBtn{
    background-image: linear-gradient(rgba(57, 146, 180, 1),rgba(131, 43, 210, 1));
}

.headContainer{
    width: 50%;
    position: relative;
}

.headContainer img{
    position: absolute;
    border-radius: 5%;
    width: 55%;
}

.headContainer #head-overlay{
    bottom: 0;
    left: 10%;
    z-index: 2;
}
.headContainer #head-background{
    bottom: 0;
    left: 40%;
    z-index: 1;
}



/* SERVICES SEECTION CSS*/

.serviceSection{
    margin: 5% 10% 5% 10%;
}

.servicesHeader{
    text-align: center;
}
.servicesHeader p{
    background: linear-gradient(rgba(57, 146, 180, 1),rgba(131, 43, 210, 1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
}

.servicePoints{
    margin-top: 10%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:11%;
}
.servicePoint{
    width: 26%;
    position: relative;
}

.sectionPointImg{
    width: 100%;
    position: absolute;
    text-align: center;
    top: -30%;
}


.sectionPointImg img{
    border: 2px solid rgba(57, 146, 180, 1);
    padding: 1%;
    border-radius: 50%;
}

.serviceCard{
    width: 100%;
    text-align: center;
    border: 2px solid rgba(57, 146, 180, 1);
    padding: 10%;
    padding-top: 30%;
    border-radius: 15%;
    background-color: rgba(38, 38, 38, 0.5);
    overflow: hidden;
}

.serviceCard h2{
    font-size: 24px;
}

.serviceCard p{
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

/* Terms and Condition */
.termHeader{
    display: flex;
    justify-content: space-between;
}

.termHeader a{
    margin-top: 2%;
}


.btn-self{
    margin: 2%;
    width: 22%;
    height: 10%;
    cursor: pointer;
    border-radius: 16px;
}

/* TERMS AND CONITION CSS */
.termsSection{
    margin: 10%;
    border: 5px solid black;
    margin: 10%;
    border: 5px solid black;
    padding: 4%;
    background-color: rgba(25, 25, 25, 0.8);
    border-radius: 25px;
    

}

.termsSection h1{
    font-size: 64px;
    
}

.termContent{
    margin-top: 5%;
    padding: 2%;
    height: 100vh;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Optional: Hide horizontal scrollbar */
}

  .termContent::-webkit-scrollbar {
    width: 10px;
  }
  .termContent::-webkit-scrollbar-track {
    background: rgba(25, 25, 25, 0.8);
  }
  .termContent::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.08); 
  }


  /* RESPONSIVENESS */

@media (max-width:640px) {   
    
/* SERVICES CSS */
    .serviceSection {
        margin-inline: auto;
    }
    .servicesHeader {
        min-width: 100%;
        margin: 10% auto;
    }
    .servicePoints {
        min-width: 100%;
        margin-inline: auto;
    }
    .servicePoint {
        min-width: 80%;
        margin: auto;
        margin-bottom: 20%;
    }
    
}

@media (max-width:740px) {
    /* HOME CSS */
    .intro {
        width: 100%;
        padding: 5% 10% 10%;
        margin-bottom: 5%;
    }
    .headContainer {
            min-width: 100%;
            min-height: 30vh;
        }
    .headBody {
            margin-inline: auto;
            min-width: 100%;
            margin-top: 0;
        }
    #head-overlay{
            max-height: 140%;
            max-width: 40%;
    }
    #head-background{
            max-height: 110%;
            max-width: 40%;
    }
}
@media (min-width:641px) and (max-width: 1023px) {
    .servicePoints {
        gap: 2%;
    }
    .servicesHeader {
        margin-bottom: 5%;
    }
    .servicePoints {
        gap: 2%;
    }
    .serviceCard p{
	    font-size:10px;
    }
    .serviceCard h2{
	    font-size:18px;
    }
    .sectionPointImg img{
        width: 50%;
    }

}

@media (max-width:900px){
    .termHeader > h1{
        font-size: 32px;
    }
}


