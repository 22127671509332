.downloadSection{
    margin: 10%;
    margin-bottom: 0;
    width: 80%;
    display: inline-flex;
    flex-wrap: wrap;
}

.downloadDesc {
    width: 50%;
    padding-bottom: 2%
}

.external-download-link img{
    margin: 4% 2% 0 0;
    width: 25%;
}
.downloadImg{
    width: 50%;
    position: relative;
}

.downloadImg img{
    position: absolute;
    width: 50%;
    border-radius: 2%;
}

.downloadImg #footer-overlay{
    bottom: 0;
    left: 0%;
    z-index: 78;
}
.downloadImg #footer-background{
    bottom: 0;
    left: 42%;
}

@media (max-width: 640px){
    .downloadImg {
            padding-bottom: 50%;
            min-width: 80%;
            margin-bottom: 2%;
            margin-inline: auto;
        }
    .downloadDesc {
            min-width: 100%;
            text-align: center;
    }
    .downloadSection {
            min-width: 100%;
            margin-inline: auto;
    }
    .external-download-link{
        text-align: center;
    }
    
    }
    