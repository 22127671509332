#faqs{
    margin:5% auto;
    width: 100%;
    display: flex;
    justify-content: center;
}
.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 5%; */
  }
  
  
  .accordion {
    width: 80%;
  }
  
  .item {
    background: black;
    margin-bottom: 5px;
    padding: 10px 20px;
  }
  
  .title {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .content {
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cube-bezier(0, 1, 0, 1);
  }
  
  .content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cube-bezier(1, 0, 1, 0);
  }

  @media (max-width:640px){
    .FAQs {
      margin-inline: auto;
      min-width: 100%;
      text-align: center;
  }
  }
  