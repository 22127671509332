
.about-container{
  display: inline-flex;
  justify-content: space-between;
  margin: 5% 10% 0 10%;
  flex-wrap: wrap;
}

.about-container-header{
  width: 50%;
  display: inline-block;
  padding: 5%;
}
.about-container-overlay{
  position: absolute;
    bottom: 0%;
    left: 0%;
    width: 90%;
    height: 100%;
}

.about-container-background{
  width: 84%;
  height: 90%;

}
.about-container-img {
  position: relative;
  width: 45%;
  height: 90%;
}

@media (max-width: 640px) {
  .about-container-img {
     margin-inline: auto;
     min-width: 60%;
     text-align: center;
 }
 .about-container-header {
     margin-inline: auto;
     min-width: 75%;
     padding: 0%;
     margin-bottom: 5%;
 }
 .about-container {
     margin-inline: auto;
     min-width: 60%;
 }
 .about-container-overlay {
     left: 8%;
 }
}

@media (min-width: 641px) and (max-width:1023px) {
 p{
  font-size: 10px;
 }
}

