.reviewHeader{
    text-align: center;
    margin: 10% 10% 5% 10%;
  }
  .testHeader{
  
    border: 5px solid red;
  }
  .reviewHeader h5{
    font-size: 20px;
    background:linear-gradient( rgba(57, 146, 180, 1), rgba(131, 43, 210, 1));
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text; 
  }
  
  .reviewData{
    position: relative;
  }

  #reviewBackImg1{
    position: absolute;
    width: 12%;
    right: 13%;
    bottom: 125%;
  }
  #reviewBackImg2{
    position: absolute;
    width: 20%;
    left: 7%;
    bottom: -45%;
  }

  .review{
    /* margin-top: 5%; */
    display: inline-flex;
    background-color: black;
    }
  
  .reviewContainer{
    display: inline-flex;
  }
  
  .reviewData{
    margin-bottom: 10%;
  }

  .reviewImg  {
    width: 50%;
  }
        
  .reviewImg img {
    width:100%;
  }
    
  .reviewDetail{
    width: 50%;
    text-align: left;
    padding: 20px;
    position: relative;
    background-color: rgba(38, 38, 38, 0.5);
  }
        
  .review.Rateby{
    position: absolute;
     bottom: 10px;
  }
  .reviewDetail > p{
    margin-top: 5%;
  }
  .reviewRateby{
    margin-top: 10%;
  }
        
  .review p{
    font-size:15px;
    margin-bottom:1px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .swiper-slide img {
    /* display: block;
    width: 100%;*/
    height: 100%; 
    object-fit: cover;
  }
  
  .swiper-slide-active .card {
    transform: scale(1.1);
  }