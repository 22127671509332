.footerCompanySection{
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerSection{
    margin: 5% 10% 2%;
    display: inline-flex;
    width: 80%;
    justify-content: space-between;
    flex-wrap: wrap;

}

.footerCompanySection span{
    margin: 10px;
}
.footer-logo{
    width: 25%;
}

.footerlogoImg{
    width:80%;
}


.contact-footer{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.policy-footer{
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.policy-footer a{
    text-decoration: none;
    color: white;
}

.contact-footer >a{
    text-decoration: none;
    color: white;
}

.socialHandal{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

}

.socialHandal img{
    width: 20px;
    margin: 0 5px;
}

@media (min-width:540px) and (max-width : 1150px){
    .footer-logo{
        min-width: 30%;
    }
    .socialHandal{
        text-align: center;
        min-width: 100%;
        margin-top: 5%;
    }
    .footerlogo{
        min-width: 100%;
    }
}

@media (max-width:540px) {
    .footer-logo{
        width: 100%;
        text-align: center;
        margin: 0 auto 5%;
    }
    .footerlogoImg{
        width: 50%;
    }
    .policy-footer{
        width: 40%;
        text-align: center;
    }
    .contact-footer{
        width: 40%;
        text-align: center;
    }
    .socialHandal{
        width: 100%;
        align-items: center;
        margin-top: 10%;
    }
}