.contact-container{
    margin: 10%;
    border: 1px solid black;
    border-radius: 4%;
    width: 80%;
    display: inline-flex;
    box-shadow: 0 0px 2px rgba(105, 79, 199, 1), 0 0px 0px  rgba(57, 146, 180, 1);
    overflow: hidden;
    flex-wrap: wrap;
}
.contact-details{
    width: 50%;
    padding:6%;
    background-color: rgba(38, 38, 38, 0.5);
}

.contact-logo{
    background-image: url("../../images/formBack.png");
    width: 50%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
}

.form-group {
    margin-bottom: 15px;
}

#contactLogoImg{
    width: 90%;
}

label {
    display: block;
    margin: 5px 0px;
    /* font-weight: bold; */
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 4px;
}

.submit-container {
    text-align: right;
}

button {
    background-image: linear-gradient(rgba(57, 146, 180, 1),rgba(131, 43, 210, 1));
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}


@media (max-width:740px){
    .contact-logo {
        min-width: 100%;
        padding: 5%;
    }
    .contact-details {
        margin-inline: auto;
        min-width: 100%;
    }
    .contact-container {
        margin-inline: auto;
        min-width: 100%;
    }
    .createContactForm {
        max-width: 70%;
        margin-inline: auto;
        padding-top: 5%;
    }
}

@media (min-width:740px) and (max-width:1025px){
    .contact-logo {
        width: 40%;
    }
    .contact-details {
       width: 60%;
    }
    .contact-container {
        
    }
    .createContactForm {
       
    }
}