.feature-flex-container{
    margin: 0% 10% 5% 10%;
    display: inline-flex;
    flex-wrap: wrap;
    width: 80%;
}

.feature-overlay{
    position: absolute;
    bottom: 15%;
    left: -8%;
    width: 90%;
    height: 90%;
}

.feature-background{
    width: 84%;
    height: 90%;

}
.feature-container{
    position: relative;
    width: 44%;
}

.feature-flex-container > .points{
    padding-top: 15px;
}

.featureSection{
    width: 46%;
    margin: 5% 5%;
}
@media (max-width: 640px) {
    .feature-flex-container {
        margin-inline: auto;
        min-width: 100%;
    }
    .feature-container {
        margin-inline: auto;
        min-width: 60%;
        text-align: center;
    }
    .featureSection {
        margin-inline: auto;
        min-width: 100%;
        padding-inline: 5%;
    }
    p{
        font-size: 12px;
    }
}
