.logo{
    background-color: black;
    width: 15%;
    margin-bottom: 20px;
}

.need-header {
    margin: auto auto;
    text-align: center; /* Centers the text inside the h2 */
}

.logo-container {
    margin-right: 10px; /* Add some space between the logo and the text, if needed */
}

.whyFYC{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin: 0 10% 10% 10%;
    gap: 20px;
    position: relative;
}

.card{
    color: white;
    background-color: rgba(38, 38, 38, 0.5);
    border-radius: 2%;
    box-shadow: 0 0px 2px rgba(105, 79, 199, 1), 0 0px 0px rgba(57, 146, 180, 1);
}

.circleborder{
        position: absolute;
        border: 2px solid white;
        border-radius: 50%;

}


.easyToRide{
    margin: 10% 10% 0;
}

.easyToRideHeader{
    width: 50%;
    font-size: 3.5rem;
    margin-bottom: 0;
}

.easySection{
    display: inline-flex;
    margin-left:25%;
}

.easySectionImg{
    width: 50%;
}

.easySectionDesc{
    width: 50%;
    padding-top: 20%;
}

#easyIphone{
    width: 100%;
}



@media (max-width:740px) {
    
    .easyToRideHeader {
        min-width: 100%;
        font-size: 1.5rem;
    }
    .easySection{
        margin: 5% 0;
    }
    .easySectionDesc{
        padding-top: 10%;
    } 
    p{
        font-size: 10px;
    }
}

@media (min-width: 741px) and (max-width: 1023px) {
    .easyToRideHeader {
        font-size: 2rem;
        width: 100%;
        margin-bottom: 5%;
    }
    .easySection{

    }
    .easySectionDesc{
        padding-top: 5%;
    }
}