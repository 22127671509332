h4{
  font-size: 24px;
}
.advantage-flex-Container {
  height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
} 
  .advantagesPoints p {
    margin-bottom: 0px;
    background-color: rgb(42, 38, 38);
  }

  .pointsAdvantages{
    width: 50%;
  }
  .pointsAdvantages > p{
    background: linear-gradient(rgba(57, 146, 180, 1), rgba(131, 43, 210, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  .points{
    padding: 2%;
    display: inline-flex;
  }
  .points img {
    width: 5%;
    margin: 2%;
     background-image: linear-gradient(rgba(57, 146, 180, 1),rgba(131, 43, 210, 1));
    border-radius: 100%;
  }
  
  .points p {
    padding-left: 10px;
    margin-bottom: 0;
    color: rgba(255,255,255,0.5);
    text-align: left;
  }

.advantagesContainer {
    display: inline-flex;
    flex-direction: column;
    position: relative;
}

.factorsShape{
  position: absolute;
  width: 40%;
  top: -20px;
}
.factorsAdvantage {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 30%;
  position: relative;
  width: 50%;
}
#keyItems01 {
  position: absolute;
  width: 50%;
  height: 120%;
  top: 81%;
  left: 0%;
}

#keyItems11 {
  width: 140%;
  height: 140%;
  margin-left: 130%;
}

#keyItems21 {
  position: absolute;
  width: 50%;
  left: 40%;
  top: 160%;
  height: 119%;
}
  
.factors{
  border: 0.5px solid black;
    box-shadow: 0 0px 2px rgba(105, 79, 199, 1), inset 0 4px 7px 0px rgba(255, 255, 255, 0.1);
    border-radius: 95%;
    text-align: center;
    padding: 10px;
  }


.factors img {
  background-image: linear-gradient(rgba(57, 146, 180, 1),rgba(131, 43, 210, 1));
  border-radius: 50%;
  width: 8%;
  height: 8%;
}

@media (max-width: 800px) {
  .factorsAdvantage h1 {
    font-size: 24px;
  }
  .factorsAdvantage p{
    font-size: 10px;
  }
  .factorsAdvantage h4{
    font-size: 18px;
  }
}

@media (max-width: 400px){ 
  #keyItems01 {
    position: absolute;
    top: 25%;
    height: 41%;
    width: 85%;
    left: 0%;
}
#keyItems11 {
    height: 110%;
    width: 125%;
    margin-left: 110%;
}
#keyItems21 {
    position: absolute;
    width: 85%;
    top: 50%;
    left: 80%;
    height: 45%;
}
.factorsAdvantage {
    margin-bottom: 0;
    padding-bottom: 60%;
}
}

@media (max-width: 740px) {
  .advantage-flex-Container {
      margin-inline: auto;
      min-width: 100%;
      display: block;
  }
  .backgray {
      padding: 0;
      min-width: 100%;
  }
  .pointsAdvantages {
      min-width: 100%;
      margin-inline: auto;
      padding: 10%;
      text-align: center;
  }
  p{
    font-size: 10px;
  }
}

@media (min-width: 400px) and (max-width: 740px) {
  
  .factorsAdvantage {
    min-width: 100%;
    grid-template-columns: 1fr 1.5fr;
    padding-bottom: 40%;
    margin-bottom: 0;
    position: relative;
    grid-template-rows: 2fr 1fr;
}
 #keyItems21 {
    position: absolute;
    width: 35%;
    top: 55%;
    left: 40%;
    height: 40%;
}
#keyItems11 {
    height: 130%;
    width: 100%;
    margin-left: 110%;
    margin-top: 5%;
}
#keyItems01 {
    position: absolute;
    top: 25%;
    height: 40%;
    width: 35%;
    left: 10%;
}
}


  @media (min-width: 740px) and (max-width: 1023px) {
    .factorsAdvantage {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 20%;
    }
    #keyItems01 {
      position: absolute;
      top: 25%;
      height: 55%;
      width: 45%;
      left: 0%;
  }
  #keyItems11 {
      height: 100%;
      width: 130%;
      margin-left: 98%;
  }
  #keyItems21 {
      position: absolute;
      width: 45%;
      top: 59%;
      left: 38%;
      height: 55%;
  }
    .factorsAdvantage h4{
      font-size: 20px;
    }
    .factorsAdvantage h1{
      font-size: 24px;
    }
}



  
  